import { useEffect } from 'react';
import { useWindowSize } from 'usehooks-ts';

import UiUtils from '../../utils/UiUtils';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

export default function ForgotSentPage() {
    const { width } = useWindowSize();
    const isMobile = UiUtils.isMobile(width);
    const isDesktop = !isMobile;

    let containerClass = "box-border w-full h-full flex flex-col items-stretch";

    containerClass = isDesktop ? `${containerClass} px-16 py-6 gap-16` : `${containerClass} px-5 py-6 gap-12`;

    /* Matomo Tracking Code */

    useEffect(() => {
        const title = 'Forgot Password - Email Sent';
        document.title = title;
        var _mtm = window._mtm = window._mtm || [];
        _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
        _mtm.push({'setDocumentTitle': title });
        var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
        g.async=true; g.src='https://matomo-staging.seedkit.com.au/js/container_VevA4SEN.js'; s.parentNode.insertBefore(g,s);
    }, []);

    return(
        <div className={containerClass}>
            <Header title="Forgot Password"/>
                <div className="container mx-auto px-3 text-black pb-10">
                    <h2 className="font-vg-medium text-black text-3xl text-center my-10">Password Reset Email Sent</h2>
                    <div className="w-full text-center">
                        <p className="font-vg-book text-base mb-2">
                            A password reset email has been sent to your email address.
                        </p>
                        <p className="font-vg-book text-base mb-2">
                            Please find the email and follow the instructions in it to change your password.
                        </p>
                    </div>
                </div>
            <Footer/>
        </div>
    );
}