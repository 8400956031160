import { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWindowSize } from 'usehooks-ts';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import AuthService from '../../services/AuthService';
import { GlobalContext } from '../../context/GlobalContext';
import UiUtils from '../../utils/UiUtils';
import * as GeneralConstants from '../../constants/GeneralConstants';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

import TextInput from '../../components/TextInput';
import Button from '../../components/Button';
//import Checkbox from '../../components/Checkbox';

export default function LoginPage() {
    const { width } = useWindowSize();
    const isMobile = UiUtils.isMobile(width);
    const isDesktop = !isMobile;

    const navigate = useNavigate();
    const { setContextValues } = useContext(GlobalContext);

    const [submitHideClass, setSubmitHideClass] = useState('');

    const processingClass = 'font-vg-regular text-black text-base text-center mt-6';
    const errorClass = 'font-vg-regular text-red text-base text-center';
    const doneClass = 'font-vg-regular text-black text-base text-center';
    const [statusClass, setStatusClass] = useState(doneClass);
    const [statusText, setStatusText] = useState('');

    const [showPassword, setShowPassword] = useState(false);

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required('Please enter an email address')
            .email('Please enter a valid email address'),
        password: Yup.string().required('Please enter a password')
    });
    const formOptions = { 
        resolver: yupResolver(validationSchema),
        defaultValues: {
            email: "",
            password: "",
        } 
    };

    const { 
        control, 
        handleSubmit
    } = useForm(formOptions);

    const saveData = (data) => {
        setContextValues([
            { key: "user", value: data.user },
            { key: "organisation", value: data.organisation }
        ]);
    }

    const showError = (error) => {
        setSubmitHideClass("");
        setStatusClass(errorClass);
        setStatusText(error.response && error.response.data ? error.response.data.message || error.message : error.message);
    }

    const onPasswordIconClick = () => {
        setShowPassword(!showPassword);
    }

    const onSubmit = (data) => {
        try {
            setSubmitHideClass("hidden");
            setStatusClass(processingClass);
            setStatusText("Logging In...");
    
            AuthService.login(data.email, data.password, AuthService.BASIC_LOGIN, true)
            .then(response => {
                if (response.data) {
                    if (response.data.use2FA) {
                        setContextValues([{ key: 'authToken', value: response.data.authToken}]);
                        navigate('/login/verify');
                    } else {
                        saveData(response.data.data);
                        navigate('/kit/home');
                    }
                } else {
                    showError({ message: GeneralConstants.LOGIN_ERROR });
                }
            })
            .catch(error => {
                console.log('error', error);
                showError(error);
            });
        } catch (error) {
            showError(error);
        }
    };

    /* Matomo Tracking Code */

    useEffect(() => {
        const title = 'Login';
        document.title = title;
        var _mtm = window._mtm = window._mtm || [];
        _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
        _mtm.push({'setDocumentTitle': title });
        var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
        g.async=true; g.src='https://matomo-staging.seedkit.com.au/js/container_VevA4SEN.js'; s.parentNode.insertBefore(g,s);
    }, []);
    
    return(
        <>
        {isDesktop && (
            <div className="box-border w-full h-full px-16 py-6 flex flex-col items-stretch gap-10">
                <Header title="Login"/>
                <div className="grid grid-cols-12 max-w-content-narrow mx-auto">
                    <div className="col-span-5 flex flex-col items-start py-12 gap-4">
                        <h2 className="font-vg-medium text-5xl text-black">Access your Seedkit account</h2>
                        <p className="font-vg-book text-base text-black mb-2">
                            Seedkit helps social enterprises measure and communicate their impact. 
                        </p>
                        <p className="font-vg-book text-base text-black">
                            This self-managed reporting platform lets you showcase your
                            organisational characteristics, operations, and impacts with customised
                            reports for stakeholders.
                        </p>
                    </div>
                    <form className="col-start-7 col-span-6 flex flex-col items-stretch gap-10 px-8 pt-12 pb-6 rounded-3xl bg-grey03" onSubmit={handleSubmit(onSubmit)}>
                        <h4 className="font-vg-medium text-[32px]">Enter your login details below</h4>
                        <div className="flex flex-col items-stretch gap-6">
                            <Controller
                                name="email"
                                control={control}
                                render={({ field, formState }) => (
                                    <TextInput 
                                        variant={formState.errors.email ? "error" : "default"}
                                        label="Email address"
                                        help={formState.errors.email?.message} 
                                        showHelp="always" 
                                        {...field}
                                    />
                                )}
                            />
                            <Controller
                                name="password"
                                control={control}
                                render={({ field, formState }) => (
                                    <TextInput
                                        type={showPassword ? "text" : "password"} 
                                        icon={showPassword ? "EyeOffIcon" : "EyeIcon"}
                                        variant={formState.errors.password ? "error" : "default"}
                                        label="Password"
                                        help={formState.errors.password?.message} 
                                        showHelp="always"
                                        onIconClick={onPasswordIconClick} 
                                        {...field}
                                    />
                                )}
                            />
                            <div className="flex flex-col items-stretch gap-6 h-32">
                                <Button type="submit" variant="solid" size="large" className={submitHideClass} label="LOGIN"/>
                                <div className="flex flex-col items-stretch gap-3">
                                    <div className={`flex justify-end ${submitHideClass}`}>
                                        {/*<Checkbox label="Remember Me" className="hidden"/>*/}
                                        <a href="/password/forgot" className="font-vg-book text-xs2 text-blue underline">Forgot Password?</a>
                                    </div>
                                    <p className={statusClass} aria-live="polite">{statusText}</p>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <Footer/>
            </div>
        )}
        {isMobile && (
            <div className="box-border w-full h-full px-5 py-[34px] flex flex-col items-stretch gap-10">
                <Header title="Login"/>
                <div className="flex flex-col items-stretch gap-4">
                    <h2 className="font-vg-medium text-black text-4xl">Access your Seedkit account</h2>
                    <p className="font-vg-book text-base text-black mb-2">
                        Seedkit helps social enterprises measure and communicate their impact. 
                    </p>
                    <p className="font-vg-book text-base text-black">
                        This self-managed reporting platform lets you showcase your
                        organisational characteristics, operations, and impacts with customised
                        reports for stakeholders.
                    </p>
                </div>
                <form className="flex flex-col items-stretch gap-8 px-6 py-12 rounded-3xl bg-grey03" onSubmit={handleSubmit(onSubmit)}>
                    <h4 className="font-vg-medium text-black text-[32px]">Enter your login details below</h4>
                    <div className="flex flex-col items-stretch gap-6">
                        <Controller
                            name="email"
                            control={control}
                            render={({ field, formState }) => (
                                <TextInput 
                                    variant={formState.errors.email ? "error" : "default"}
                                    label="Email address"
                                    help={formState.errors.email?.message} 
                                    showHelp="always" 
                                    {...field}
                                />
                            )}
                        />
                        <Controller
                            name="password"
                            control={control}
                            render={({ field, formState }) => (
                                <TextInput
                                    type="password" 
                                    variant={formState.errors.password ? "error" : "default"}
                                    label="Password"
                                    help={formState.errors.password?.message} 
                                    showHelp="always" 
                                    {...field}
                                />
                            )}
                        />
                        <div className="flex flex-col items-stretch gap-6 h-32">
                            <Button variant="solid" size="large" className={submitHideClass} label="LOGIN"/>
                            <div className="flex flex-col items-stretch gap-3">
                                <div className={`flex justify-end ${submitHideClass}`}>
                                    {/*<Checkbox label="Remember Me" className="hidden"/>*/}
                                    <a href="/password/forgot" className="font-vg-book text-xs2 text-blue underline">Forgot Password?</a>
                                </div>
                                <p className={statusClass} aria-live="polite">{statusText}</p>
                            </div>
                        </div>
                    </div>
                </form>
                <Footer/>
            </div>
        )}
        </>
    );
}
