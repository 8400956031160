import { useState, useEffect } from 'react';
import { Buffer } from 'buffer';
import { useWindowSize } from 'usehooks-ts';

import UiUtils from '../../utils/UiUtils';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

export default function TermsPage() {
    const { width } = useWindowSize();
    const isMobile = UiUtils.isMobile(width);
    const isDesktop = !isMobile;

    let containerClass = "box-border w-full h-full flex flex-col items-stretch";

    containerClass = isDesktop ? `${containerClass} px-16 py-6 gap-16` : `${containerClass} px-5 py-6 gap-12`;

    const p1 = `This project has been approved by or on behalf of Swinburne's Human Research Ethics Committee (SUHREC) 
    and UoM's Office of Research Ethics and Integrity in line with the <i>National Statement on Ethical Conduct in Human Research</i> 
    (Swinburne ethics reference number 20226639-10603). All research will be conducted in line with these approvals. If you have any 
    concerns or complaints about the conduct of this project, you can contact Swinburne's Research Ethics Officer on 
    +61 3 9214 5218`;

    const p2 = `The information that we collect from you as part of your use of Seedkit will be stored securely at UoM. This includes 
    identifying information such as names or email addresses, which you have provided in order to create and access your user account. 
    Users can at any time request to access and securely delete their records, including user names, passwords, organisational details, 
    data entered and reports produced in Seedkit, by contacting the Seedkit team in writing `;

    const p3 = `In accordance with the Australian Privacy Principles, you have the right to access the information collected and stored about you. 
    You also have the right to request that any information with which you disagree, be corrected. In accordance with the Australian Privacy Principles, 
    you have the right to access the information collected and stored by the researchers about you. 
    You also have the right to request that any information with which you disagree, be corrected.<br/><br/>
    If you would like to access your information, please contact the Seedkit team `;

    const p4 = `Technical difficulties should be reported to the Seedkit team `;

    const p5 = `You are responsible for maintaining the confidentiality of your password and shall be held 
    responsible for all site access via your registration and/or login, whether authorised or unauthorised by you. 
    You agree to immediately notify us of any unauthorised use of your registration, user account, or password `;

    const p6 = `Material contained on Seedkit is the copyright of UoM (except where otherwise indicated) or is
    used with permission or under licence of the copyright owner. You may embed, download, print and reproduce the indicator tracking 
    charts/graphs that you create in the Seedkit using your own data.<br/><br/>
    All other rights are reserved. `;

    //const [para1, setPara1] = useState(p1);
    const [para2, setPara2] = useState(p2);
    const [para3, setPara3] = useState(p3);
    const [para4, setPara4] = useState(p4);
    const [para5, setPara5] = useState(p5);
    const [para6, setPara6] = useState(p6);

    useEffect(() => {
        //const e1 = Buffer.from('IG9yIHZpYSBlbWFpbCBhdCByZXNldGhpY3NAc3dpbi5lZHUuYXU=', 'base64').toString('ascii');
        const e2 = Buffer.from('IGF0IDxhIGhyZWY9Im1haWx0bzphZG1pbkBzZWVka2l0LmNvbS5hdSI+YWRtaW5Ac2VlZGtpdC5jb20uYXU8L2E+', 'base64').toString('ascii');
        const e3 = Buffer.from('IGJ5IGVtYWlsaW5nIDxhIGhyZWY9Im1haWx0bzphZG1pbkBzZWVka2l0LmNvbS5hdSI+YWRtaW5Ac2VlZGtpdC5jb20uYXU8L2E+', 'base64').toString('ascii');
        const e4 = Buffer.from('IGJ5IGVtYWlsaW5nOiA8YSBocmVmPSJtYWlsdG86YWRtaW5Ac2VlZGtpdC5jb20uYXUiPmFkbWluQHNlZWRraXQuY29tLmF1PC9hPg==', 'base64').toString('ascii');
        const e5 = Buffer.from('IGJ5IGVtYWlsaW5nIDxhIGhyZWY9Im1haWx0bzphZG1pbkBzZWVka2l0LmNvbS5hdSI+YWRtaW5Ac2VlZGtpdC5jb20uYXU8L2E+', 'base64').toString('ascii');
        const e6 = Buffer.from('IFJlcXVlc3RzIGZvciBmdXJ0aGVyIHBlcm1pc3Npb24gdG8gdXNlIHRoaXMgbWF0ZXJpYWwgc2hvdWxkIGJlIGRpcmVjdGVkIHRvOiA8YSBocmVmPSJtYWlsdG86YWRtaW5Ac2VlZGtpdC5jb20uYXUiPmFkbWluQHNlZWRraXQuY29tLmF1PC9hPi4=', 'base64').toString('ascii');

        //setPara1(p1 + e1);
        setPara2(p2 + e2);
        setPara3(p3 + e3);
        setPara4(p4 + e4);
        setPara5(p5 + e5);
        setPara6(p6 + e6);
    }, [p1, p2, p3, p4, p5, p6]);

    /* Matomo Tracking Code */

    useEffect(() => {
        const title = 'Terms of Use';
        document.title = title;
        var _mtm = window._mtm = window._mtm || [];
        _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
        _mtm.push({'setDocumentTitle': title });
        var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
        g.async=true; g.src='https://matomo-staging.seedkit.com.au/js/container_VevA4SEN.js'; s.parentNode.insertBefore(g,s);
    }, []);
    
    return(
        <div className={containerClass}>
            <Header title="Terms of Use"/>
            <div className="container mx-auto text-black pb-10 max-w-content-narrow">
                <p className="font-vg-book text-base mb-5">
                Services provided on this website, titled "Seedkit", are provided by Melbourne Social Equity Institute, the University of Melbourne ("UoM"). 
                </p>
                <p className="font-vg-book text-base">
                Please read the <strong>Terms of Use</strong> carefully. By registering for an account on this site you agree to comply with and be bound by these <strong>Terms of Use</strong>. 
                If you do not wish to be bound by the <strong>Terms of Use</strong>, do not register for, or use, Seedkit.
                </p>

                <h2 className="font-vg-medium text-xl my-5">1. Acceptance of Terms of Use</h2>
                <p className="font-vg-book text-base">
                Each time you use or cause access to Seedkit, you confirm your agreement to be bound by the site's <strong>Terms of Use</strong>. 
                If you use or access a particular service on or through this website, you will also be subject to any rules or guidelines applicable 
                to that service. Please note if the service or website is a third party site, UoM has no control over that site.
                </p>

                <h2 className="font-vg-medium text-xl mt-5">2. Services</h2>

                <h3 className="font-vg-medium text-lg my-5">2.1 Website Contents</h3>
                <p className="font-vg-book text-base">
                The services on Seedkit include an online evaluation tool, which allows users to enter their organisational 
                and enterprise characteristics, and select and track enterprise performance and social impact indicators. 
                Seedkit also provides information resources about measuring and communicating social impacts. 
                <br/><br/>
                Seedkit is an open-access platform and social enterprise users can register for an account free of charge.
                <br/><br/>
                While Seedkit strives to provide accurate and valuable services, we do not guarantee 
                the accuracy or completeness of the information provided through our platform. 		
                </p>
                
                <h3 className="font-vg-medium text-lg my-5">2.2 Limitations of Services</h3>
                <p className="font-vg-book text-base mb-5">
                While there are benefits to this service being available online, it is also acknowledged that there are limitations 
                to the services provided on Seedkit. The services available on Seedkit website are not intended to replace face-to-face 
                or other traditional forms of support or advice you may receive for operating and reporting on your social enterprise 
                as required by the relevant laws and regulatory requirements.
                </p>
                <p className="font-vg-book text-base mb-5">
                Therefore, if at any time you believe that Seedkit does not fully meet or address your needs, or if other concerns about 
                your social enterprise operations or reporting arise, you should seek the services of an appropriate professional.
                </p>
                <p className="font-vg-book text-base">
                Users acknowledge that the services are offered as a self-managed tool for assessment and tracking and should not replace 
                professional advice or compliance with relevant laws and regulations. The services on Seedkit will not be appropriate if:
                </p>
                <ul className="list-disc font-vg-book text-base mt-2">
                <li className="mb-2 ml-8">
                    you do not have permission to enter your social enterprise data into the tool.
                </li>
                <li className="mb-2 ml-8">
                    you do not want the data you enter to be stored in the Seedkit database.
                </li>
                <li className="mb-2 ml-8">
                    you need a professional to review or audit your social enterprise's information 
                    in order to meet your enterprise's required reporting compliance standards.
                </li>
                </ul>

                <h3 className="font-vg-medium text-lg my-5">2.3 Services Provided in Australia</h3>
                <p className="font-vg-book text-base">
                Seedkit is designed and delivered in accordance with Australian guidelines. The service is intended for use by 
                social enterprises based and operating in Australia, and it is not implied or intended that the service complies 
                with the laws or standards of other jurisdictions.
                </p>

                <h2 className="font-vg-medium text-xl my-5">3. Research</h2>
                <p className="font-vg-book text-base mb-5">
                Seedkit enables social enterprise to better understand, measure and communicate their organisational characteristics, 
                operations and impacts in a consistent manner and for a variety of stakeholders. In addition, data entered onto Seedkit 
                will be used in a de-identified form to provide aggregated insights about the Australian social enterprise sector, 
                to inform policy and build the evidence base for the sector based on its economic and social contributions.
                </p>
                <p className="font-vg-book text-base mb-5">
                The research teams from UoM uses data entered onto Seedkit for academic research and development purposes. 
                For example, this may include comparing users' data on their social enterprise demographics and structure, business 
                and financial performance, and outcomes achieved in relation to the social purpose of their enterprise. 
                </p>
                <p className="font-vg-book text-base mb-5">
                This research is used to inform the development of publishable research outputs. Publications and reports produced 
                as a result of using Seedkit data may be made publicly available, including through the Seedkit website.
                </p>

                <h2 className="font-vg-medium text-xl my-5">4. Modification or Discontinuance</h2>
                <p className="font-vg-book text-base">
                UoM exclusively reserves the right to, and may at any time and without notice and any liability, 
                modify or discontinue this website and its services, whether temporarily or permanently.
                </p>

                <h2 className="font-vg-medium text-xl my-5">5. Security and Confidentiality</h2>
                <p className="font-vg-book text-base">
                In order to protect the privacy of consumers, Seedkit uses and implements professional and industry standards for 
                the security and protection of personal information.  For further information please see the Seedkit <a href="/privacy">Privacy Policy</a>. 
                <br/><br/>
                It is noted that the provision of services through this website is subject to security risks inherent in any internet service. 
                While the Seedkit team endeavours to ensure that your personal information is secure, it is not possible to safeguard against 
                all possible breaches of security. You are advised to ensure that the computer terminal from which you send email and messages is secure, 
                and to ensure that you log out of Seedkit after you have finished a session.
                <br/><br/>
                The transmission of all information is at your own risk.
                </p>

                <h3 className="font-vg-medium text-lg my-5">5.1 Privacy and Confidentiality</h3>
                <p className="font-vg-book text-base mb-5">
                Seedkit can be accessed by eligible users via a username and password. When you first register to use the website, 
                you are asked to provide your email address. You will also be asked to provide your first and last names, so that 
                contact with you can be personalised.
                </p>
                <p className="font-vg-book text-base mb-5">
                After you log in to the site, you will be asked to complete questions about your organisation and social enterprise(s). Responses to 
                these questions will be stored against your registration, so that changes over time can be monitored.
                </p>
                <p className="font-vg-book text-base mb-5" dangerouslySetInnerHTML={{__html: para2}}/>
                <p className="font-vg-book text-base">
                By creating an account and using Seedkit, you are indicating consent to have your responses 
                and data stored in our secure database and used for the following relevant purposes:
                </p>
                <ul className="list-disc font-vg-book text-base mt-2">
                <li className="mb-2 ml-8">
                    The data you enter will be used to generate reports (dashboards) for you.
                </li>
                <li className="mb-2 ml-8">
                    Aggregated information from the Seedkit system will be analysed to provide 
                    general insights about the social enterprise sector in Australia.
                </li>
                <li className="mb-2 ml-8">
                    Insights based on aggregated data may be published or communicated, but no 
                    individual organisation or user information will be identified in any public communications 
                    without the express permission of the relevant organisation and user.
                </li>
                <li className="mb-2 ml-8">
                    With the data collected by Seedkit, the UoM research team will produce:
                    <ul className="list-disc font-vg-book text-base mt-2">
                        <li className="mb-2 ml-8">
                            Research and Analysis reports on the findings from analysed Seedkit data.
                        </li>
                        <li className="mb-2 ml-8">
                            A dataset - with identifying information removed - that can be used by others 
                            for non-commercial research and social enterprise development purposes.
                        </li>
                    </ul>
                </li>
                </ul>
                <p className="font-vg-book text-base mb-5" dangerouslySetInnerHTML={{__html: para3}}/>
                <p className="font-vg-book text-base">
                Further information about the ways in which the privacy of your information is secured can be found on the <a href="/privacy">Privacy Policy</a> for this site.
                </p>

                <h3 className="font-vg-medium text-lg my-5">5.2 Limits to Confidentiality</h3>
                <p className="font-vg-book text-base">
                The Seedkit team confirms that the information you provide in emails to the team, 
                or in response to any other research tools, processes or methodologies, will not be revealed, 
                disclosed, sold, distributed, rented, licensed, shared or otherwise passed on to any third party, unless: 
                (1) we have your express permission to do so; (2) where required by law to provide information in response to a subpoena or warrant, 
                or (3) in cases where the potential risk of harm, or threat to the safety of a consumer or another, requires such disclosure 
                for the purpose of minimising or negating that risk.
                </p>

                <h3 className="font-vg-medium text-lg my-5">5.3 Communication of Information to Others</h3>
                <p className="font-vg-book text-base">
                Where information is used for the purposes of research and publication, the 'Policy on the Conduct of Research' 
                will be strictly adhered to. If used for research or publication purposes, your information will be analysed 
                in a de-identifiable form and disseminated results will not contain material capable of identifying you.
                </p>

                <h3 className="font-vg-medium text-lg my-5">5.4 Feedback</h3>
                <p className="font-vg-book text-base" dangerouslySetInnerHTML={{__html: para4}}/>

                <h2 className="font-vg-medium text-xl my-5">6. Your Responsibilities</h2>
                <h3 className="font-vg-medium text-lg my-5">6.1 Age</h3>
                <p className="font-vg-book text-base">
                Access and use of Seedkit is only permitted to persons 18 years or older, who have permission 
                to enter data about their social enterprise into the tool.
                </p>
                <h3 className="font-vg-medium text-lg my-5">6.2 Registration</h3>
                <p className="font-vg-book text-base">
                All users of Seedkit must create an account via the registration process, and agree to provide 
                truthful information when requested. When creating an account, you are required to acknowledge that you have read, 
                understood, and agree to these <strong>Terms of Use</strong>.
                </p>
                <h3 className="font-vg-medium text-lg my-5">6.3 Password</h3>
                <p className="font-vg-book text-base" dangerouslySetInnerHTML={{__html: para5}}/>

                <h3 className="font-vg-medium text-lg my-5">6.4 Confidentiality of Your Data</h3>
                <p className="font-vg-book text-base">
                You are responsible for maintaining the confidentiality of any data you download from the site onto your computer.
                </p>
                
                <h3 className="font-vg-medium text-lg my-5">6.5 User Conduct</h3>
                <p className="font-vg-book text-base">
                All Users of Seedkit are expected to conduct themselves responsibly and in a manner that respects the platform and its community. 
                This includes, but is not limited to, refraining from engaging in any of the following prohibited activities:
                </p>
                <ul className="list-disc font-vg-book text-base mt-2">
                <li className="mb-2 ml-8">
                    Providing inaccurate, false, or misleading information during registration or while using the platform.
                </li>
                <li className="mb-2 ml-8">
                    Posting, sharing, or transmitting content that is unlawful, defamatory, libelous, harmful, abusive, 
                    harassing, invasive of others' privacy, or otherwise objectionable.
                </li>
                <li className="mb-2 ml-8">
                    Attempting to gain unauthorised access to the platform, its servers, or any connected systems.
                </li>
                <li className="mb-2 ml-8">
                    Engaging in spamming, phishing, or any other activities that could disrupt the platform or harm other users.
                </li>
                <li className="mb-2 ml-8">
                    Violating the intellectual property rights of others, including copyright, trademarks, or patents.
                </li>
                <li className="mb-2 ml-8">
                    Promoting or engaging in any illegal activities through the platform.
                </li>
                </ul>

                <h2 className="font-vg-medium text-xl my-5">7. Termination of Accounts</h2>
                <p className="font-vg-book text-base mb-5">
                The Seedkit team may take steps, as necessary, to investigate any violation of these <strong>Terms of Use</strong>, 
                including the recording, distribution, or publication of any site material or communications, and any misuse or 
                unauthorised access to site material that occurs.
                </p>
                <p className="font-vg-book text-base">
                UoM reserves the right to cancel your registration and prevent your further use of Seedkit with or without prior notice, 
                if you are found to have contravened these <strong>Terms of Use</strong> or if your conduct disrupts the platform's functionality or community.
                <br/><br/>
                Termination of accounts may result in the loss of access to the platform and its services.
                </p>

                <h2 className="font-vg-medium text-xl my-5">8. Copyright and Intellectual Property</h2>
                <p className="font-vg-book text-base" dangerouslySetInnerHTML={{__html: para6}}/>

                <h2 className="font-vg-medium text-xl my-5">9. No Liability</h2>
                <p className="font-vg-book text-base mb-5">
                One of the key purposes of Seedkit is to provide you with an online platform which enables you to enter data 
                on the enterprise performance and social impact indicators your social enterprise measures, and to monitor 
                and track these impacts over time. Any benefit derived from your use of the website is entirely dependent on you.
                </p>
                <p className="font-vg-book text-base mb-5">
                UoM makes no representations or warranties in relation to your use of the website, or the potential benefits 
                or outcomes that may result. UoM will not be liable for any loss or damage suffered or incurred in connection 
                with your use of the website.
                </p>
                <p className="font-vg-book text-base">Last updated: 28 August 2024</p>
            </div>
            <Footer/>
        </div>
   );
}
