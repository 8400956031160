import { useCallback, useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWindowSize } from 'usehooks-ts';

import AuthService from '../../services/AuthService';
import ResourceService from '../../services/ResourceService';
import UiUtils from '../../utils/UiUtils';
import * as UiConstants from '../../constants/UiConstants';

import NavBar from '../../components/NavBar';
import Footer from '../../components/Footer';
import Button from '../../components/Button';
import HomeFeature from '../../components/HomeFeature';
import HomeCard from '../../components/HomeCard';
import HomeStep from '../../components/HomeStep';
import HomeResourceBlock from '../../components/HomeResourceBlock';
import Testimonial from '../../components/Testimonial';
import FaqSet from '../../components/FaqSet';
import SubscribeForm from '../../components/SubscribeForm';
import LogoBlock from '../../components/LogoBlock';

import LMCFLogo from '../../images/testimonial_logo_lmcf.png';
import LMCFAvatar from '../../images/testimonial_avatar_lmcf.jpg';
import GoodCycleLogo from '../../images/testimonial_logo_good_cycle.png';
import GoodCycleAvatar from '../../images/testimonial_avatar_good_cycle.png';
import ServantsLogo from '../../images/testimonial_logo_servants.png';
import ServantsAvatar from '../../images/testimonial_avatar_servants.jpg';
import WhiteBoxLogo from '../../images/testimonial_logo_white_box.png';
import WhiteBoxAvatar from '../../images/testimonial_avatar_white_box.jpg';

export default function HomePage() {
    const { width } = useWindowSize();
    const isMobile = UiUtils.isMobile(width);
    const isDesktop = !isMobile;

    const navigate = useNavigate();
    
    const loggedIn = AuthService.isLoggedIn();

    const getAvatar = (key) => {
        switch (key) {
            case "lmcf":
                return LMCFAvatar;
            case "goodCycle":
                return GoodCycleAvatar;
            case "servants":
                return ServantsAvatar;
            case "whiteBox":
                return WhiteBoxAvatar;
            default:
                return "";
        }
    }

    const getLogo = (key) => {
        switch (key) {
            case "lmcf":
                return LMCFLogo;
            case "goodCycle":
                return GoodCycleLogo;
            case "servants":
                return ServantsLogo;
            case "whiteBox":
                return WhiteBoxLogo;
            default:
                return "";
        }
    }

    const testimonials = [
        {
            quote: "Social enterprises have social or environmental impact at their centre. Measuring impact is critical for designing a highly effective enterprise and for engaging customers and investors. Seedkit is an outstanding resource designed specifically for social enterprises.",
            name: "Dr Catherine Brown OAM",
            role: "CEO, Lord Mayor's Charitable Foundation",
            key: "lmcf",
            logoAlt: "Logo of Lord Mayor's Charitable Foundation",
            avatarAlt: "Image of Dr Catherine Brown OAM"
        },
        {
            quote: "Social impact measurement is key to supporting internal decision-making, program monitoring and improvement, and informing organisational strategy. But the real importance of social impact measurement for social enterprises lies in creating the evidence to leverage and put to policymakers and key decision-makers.",
            name: "Hannah Figueroa",
            role: "Impact Evaluation Lead, Good Cycles",
            key: "goodCycle",
            logoAlt: "Logo of Good Cycle",
            avatarAlt: "Image of Hannah Figueroa"
        },
        {
            quote: "Although appetite for social impact measurement is growing, it's nearly impossible for lean social service organisations like ours to produce the increasingly complex data government, supporters, and partners want. Tools like Seedkit can measure everything in one place and automate our reporting, saving time and money for the work that matters most.",
            name: "Jayden Battey",
            role: "Communications & Engagement Manager, Servants Community Housing",
            key: "servants",
            logoAlt: "Logo of Servants Community Housing",
            avatarAlt: "Image of Jayden Battey"
        },
        {
            quote: "If we want to change the world we need the data behind the change that we want to see. I’m so excited about this work! Seedkit will game change the collection of data for the Social Enterprise Movement in Australia.",
            name: "Luke Terry",
            role: "CEO, White Box Enterprises",
            key: "whiteBox",
            logoAlt: "Logo of White Box Enterprises",
            avatarAlt: "Image of Luke Terry"
        }
    ];

    let testIndexes = [0, 1, 2, 3];
    const t1 = Math.floor(Math.random() * testIndexes.length);
    const testimonialIndex1 = testIndexes[t1];
    testIndexes.splice(t1, 1);
    const t2 = Math.floor(Math.random() * testIndexes.length);
    const testimonialIndex2 = testIndexes[t2];

    const [articleState, setArticleState] = useState({
        news: {},
        learn: {},
        loaded: false
    });

    const subscribeFormRef = useRef(null);

    const loadArticles = useCallback(() => {
        ResourceService.getResources(true, UiConstants.RESOURCE_TYPE.All)
        .then(response => {
            const resources = response.data.resources;
            const newArticles = resources.filter(r => r.category === UiConstants.RESOURCE_TYPE.News);
            const newsIndex = Math.floor(Math.random() * newArticles.length);
            const learnArticles = resources.filter(r => r.category === UiConstants.RESOURCE_TYPE.Learn);
            const learnIndex = Math.floor(Math.random() * learnArticles.length);
            const newState = {
                news: newArticles[newsIndex],
                learn: learnArticles[learnIndex],
                loaded: true
            };
            setArticleState(newState);
        })
        .catch(err => {
            console.log(err);
            setArticleState({
                news: {},
                learn: {},
                loaded: false
            });
        });
    }, [setArticleState]);

    useEffect(() => {
        loadArticles();
    }, [loadArticles]);

    function doLogin() {
        if (loggedIn) {
            navigate("/kit/home");
        } else {
            navigate("/login");
        }
    }

    function doSignUp() {
        if (loggedIn) {
            navigate("/kit/home");
        } else {
            navigate("/signup");
        }
    }

    /* Matomo Tracking Code */

    useEffect(() => {
        const title = 'Seedkit';
        document.title = title;
        var _mtm = window._mtm = window._mtm || [];
        _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
        _mtm.push({'setDocumentTitle': 'Home' });
        var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
        g.async=true; g.src='https://matomo-staging.seedkit.com.au/js/container_VevA4SEN.js'; s.parentNode.insertBefore(g,s);
    }, []);
    
    return(
        <>
        {isDesktop && (
            <div className="box-border w-full h-full py-6 flex flex-col items-stretch gap-10">
                <div className="box-border flex flex-col items-stretch gap-10 py-10 px-8 mx-16 rounded-3xl bg-home-hero bg-right-bottom bg-contain bg-no-repeat bg-light-green01">
                <NavBar/>
                <div className="w-full px-6 py-10 border-box max-w-content-narrow mx-auto">
                    <div className="flex flex-col items-start gap-8 max-w-[699px]">
                        <h1 className="font-vg-medium text-black text-[64px] leading-110">
                            Track and communicate social enterprise impacts
                        </h1>
                        <p className="font-vg-regular text-black text-xl">
                            Embark on your social impact journey with Seedkit.<br/> 
                            Streamline your data tracking and magnify positive change.
                        </p>
                        <div className="flex justify-start items-center gap-12">
                            {!AuthService.isLoggedIn() && (
                            <Button variant="solid" size="large" label="SIGN UP" onClick={doSignUp}/>
                            )}
                            <Button 
                            variant="solid" 
                            size="large" 
                            label={AuthService.isLoggedIn() ? "GO TO YOUR ACCOUNT" : "LOGIN"} 
                            rightIcon="ArrowRightIcon" 
                            className="min-w-[204px]" 
                            onClick={doLogin}
                            />
                        </div>
                    </div>
                </div>
                </div>
                <div className="box-border flex flex-col items-stretch gap-10 bg-home-feature-card bg-right-bottom bg-contain bg-no-repeat">
                <div className="grid grid-cols-12 py-10 px-16 border-box max-w-content mx-auto">
                    <div className="col-span-4">
                        <h2 className="font-vg-medium text-black text-5xl leading-110">
                            Social Enterprise Impact Reporting, Simplified
                        </h2>
                    </div>
                    <div className="col-start-6 col-span-6 flex flex-col items-stretch gap-8">
                        <div className="grid grid-cols-2 gap-6">
                            <HomeFeature 
                            icon="EmojiHappyIcon"
                            title="Easy to use"
                            text="Streamline processes and simplify impact measurement with Seedkit's intuitive platform that works how you work - saving time and effort."
                            />
                            <HomeFeature 
                            icon="IdentificationIcon"
                            title="Tailor your indicators"
                            text="Customise impact metrics to fit your organisation's goals. You can select and adapt indicators that best reflect your unique social enterprise needs."
                            />
                        </div>
                        <div className="grid grid-cols-2 gap-6">
                            <HomeFeature 
                            icon="ChartPieIcon"
                            title="Visualise your data"
                            text="Transform complex impact and business data with visualisation tools that help you compellingly present key trends."
                            />
                            <HomeFeature 
                            icon="DocumentReportIcon"
                            title="Create reports"
                            text="Effortlessly generate reports showcasing your organisation's achievements, empowering you to effectively communicate your social enterprise's impact to stakeholders."
                            />
                        </div>
                    </div>
                </div>
                <div className="flex flex-col items-stretch px-16 py-10 gap-8 border-box max-w-content mx-auto">
                    <div className="flex flex-col items-stretch gap-3">
                        <h2 className="font-vg-medium text-black text-5xl">Measure and showcase impact with confidence</h2>
                    </div>
                    <div className="grid grid-cols-3 gap-6">
                        <HomeCard
                            colour="green"
                            title="Sharing your business impact story"
                            text="Seedkit helps document, measure, and communicate your organisational characteristics and impacts with a single source of truth, so you can 'enter once and report often' to your employees, board, funders and customers."
                            image="/assets/home_card_1_400.jpg"
                            imageAlt="A young adult is smiling and holding a bag of food. Behind them, four people in volunteering shirts sort through boxes."
                        />
                        <HomeCard
                            colour="green"
                            title="Strengthen impact measurement skills"
                            text="Seedkit provides indicators, learning resources and user guidance to improve how you measure and communicate your social enterprise's impact and business success."
                            image="/assets/home_card_2_400.jpg"
                            imageAlt="Four people working together around a desk to sort donated clothes and food."
                        />
                        <HomeCard
                            colour="green"
                            title="Building public knowledge"
                            text="Seedkit is a powerful source of information about the social enterprise sector. Anonymised and aggregated data is analysed by experts to support strategy and policy advice that helps grow the sector."
                            image="/assets/home_card_3_400.jpg"
                            imageAlt="Eight people are standing in a circle outside, stacking their hands. They hold cleaning gloves and supplies."
                        />
                    </div>
                </div>
                </div>
                <div className="flex flex-col items-stretch">
                <div className=" bg-home-steps bg-right-bottom bg-contain bg-no-repeat bg-grey04">
                    <div className="flex flex-col items-stretch px-16 py-20 gap-12 border-box max-w-content mx-auto">
                        <h2 className="font-vg-medium text-black text-5xl leading-110">
                            Seedkit works in 4 easy steps
                        </h2>
                        <div className="grid grid-cols-4 gap-5">
                            <HomeStep
                            number="01"
                            title="You register"
                            text="Set up an organisation account and register one or more social enterprises."
                            />
                            <HomeStep
                            number="02"
                            title="Choose indicators"
                            text="Select or create indicators to manage your operations and report on how your social enterprise is changing the world."
                            />
                            <HomeStep
                            number="03"
                            title="Enter data"
                            text="Define and submit data on indicators you want to measure regularly."
                            />
                            <HomeStep
                            number="04"
                            title="Communicate impact"
                            text="Create and export reports from dashboards and share them with stakeholders."
                            />
                        </div>
                    </div>
                </div>
                <div className="bg-grey03 bg-home-faq bg-right-top bg-contain bg-no-repeat">
                    <div className="flex flex-col items-stretch justify-center gap-12 p-16 border-box max-w-content mx-auto">
                        <h3 className="font-vg-medium text-black text-[38px] leading-120 text-center">Frequently asked questions</h3>
                        <div className="flex flex-col items-stretch gap-1">
                            <FaqSet/>
                        </div>
                    </div>
                </div>
                </div>
                <div className="grid grid-cols-12 gap-6 py-16 px-16 max-w-content mx-auto">
                <div className="col-span-5 col-start-1">
                    <Testimonial
                        quote={testimonials[testimonialIndex1].quote}
                        name={testimonials[testimonialIndex1].name}
                        role={testimonials[testimonialIndex1].role}
                        logo={getLogo(testimonials[testimonialIndex1].key)}
                        logoAlt={testimonials[testimonialIndex1].logoAlt}
                        avatar={getAvatar(testimonials[testimonialIndex1].key)}
                        avatarAlt={testimonials[testimonialIndex1].avatarAlt}
                    />
                </div>
                <div className="col-span-5 col-start-7">
                    <Testimonial
                        quote={testimonials[testimonialIndex2].quote}
                        name={testimonials[testimonialIndex2].name}
                        role={testimonials[testimonialIndex2].role}
                        logo={getLogo(testimonials[testimonialIndex2].key)}
                        logoAlt={testimonials[testimonialIndex2].logoAlt}
                        avatar={getAvatar(testimonials[testimonialIndex2].key)}
                        avatarAlt={testimonials[testimonialIndex2].avatarAlt}
                    />
                </div>
                </div>
                {articleState.loaded === true && (
                <div className="flex flex-col items-stretch py-10 gap-12 px-16 max-w-content mx-auto">
                <div className="flex flex-col items-start gap-4">
                    <h3 className="font-vg-medium text-black text-[38px] leading-120">
                        Impact measurement news and resources
                    </h3>
                    <p className="font-vg-book text-black text-base leading-130">
                        Access product updates, learning resources, events and more.
                    </p>
                </div>
                <div className="grid grid-cols-2 gap-6">
                    <HomeResourceBlock
                        type={articleState.news.resourceType}
                        tags={[{ text: "News", color: "green" }]}
                        image={articleState.news.heroImage.largeUrl}
                        title={articleState.news.title}
                        text={articleState.news.lead}
                        link={`/resources/${articleState.news.key}`}
                    />
                    <HomeResourceBlock
                        type={articleState.learn.resourceType}
                        tags={[{ text: "Learn", color: "green" }]}
                        image={articleState.learn.heroImage.largeUrl}
                        title={articleState.learn.title}
                        text={articleState.learn.lead}
                        link={`/resources/${articleState.learn.key}`}
                    />
                </div>
                </div>
                )}
                <div ref={subscribeFormRef} className="px-16">
                <SubscribeForm/>
                </div>
                <LogoBlock/>
                <Footer className="mx-16"/>
            </div>
        )}
        {isMobile && (
            <div className="box-border w-full h-full py-6 flex flex-col items-stretch gap-8">
                <div className="box-border flex flex-col gap-8 pt-6 px-6 pb-12 rounded-3xl bg-home-hero-mobile bg-right-bottom bg-contain bg-no-repeat bg-light-green01 mx-5">
                <NavBar/>
                <div className="flex flex-col items-start gap-6">
                    <h1 className="font-vg-medium text-black text-4xl">
                        Track and communicate social enterprise impacts
                    </h1>
                    <p className="font-vg-book text-black text-base">
                        Embark on your social impact journey with Seedkit. 
                        Streamline your data tracking and magnify positive change.
                    </p>
                    {!AuthService.isLoggedIn() && (
                        <Button variant="solid" size="large" label="SIGN UP" onClick={doSignUp}/>
                    )}
                    <Button variant="solid" size="large" className="w-full" label={AuthService.isLoggedIn() ? "GO TO YOUR ACCOUNT" : "LOGIN"} rightIcon="ArrowRightIcon" onClick={doLogin}/>
                </div>
                </div>
                <div className="box-border flex flex-col items-stretch gap-8 py-8 px-6 mx-5">
                <h2 className="font-vg-medium text-black text-4xl">Social Enterprise Impact Reporting, Simplified</h2>
                <div className="flex flex-col items-stretch gap-6">
                    <HomeFeature 
                        icon="EmojiHappyIcon"
                        title="Easy to use"
                        text="Streamline processes and simplify impact measurement with Seedkit's intuitive platform that works how you work - saving time and effort."
                    />
                    <HomeFeature 
                        icon="IdentificationIcon"
                        title="Tailor your indicators"
                        text="Customise impact metrics to fit your organisation's goals. You can select and adapt indicators that best reflect your unique social enterprise needs."
                    />
                    <HomeFeature 
                        icon="ChartPieIcon"
                        title="Visualise your data"
                        text="Transform complex impact and business data with visualisation tools that help you compellingly present key trends."
                    />
                    <HomeFeature 
                        icon="DocumentReportIcon"
                        title="Create reports"
                        text="Effortlessly generate reports showcasing your organisation's achievements, empowering you to effectively communicate your social enterprise's impact to stakeholders."
                    />
                </div>
                </div>
                <div className="flex flex-col items-stretch pt-8 gap-8">
                <h2 className="font-vg-medium text-black text-4xl px-5">Measure and showcase impact with confidence</h2>
                <div className="flex flex-col items-stretch gap-6 px-5">
                    <HomeCard
                        colour="green"
                        title="Sharing your business impact story"
                        text="Seedkit helps you document, measure, and communicate your organisational characteristics and impacts with a single source of truth, so you can 'enter once and report often' to your employees, board, funders and customers."
                        image="/assets/home_card_1.jpg"
                        imageAlt="A young adult is smiling and holding a bag of food. Behind them, four people in volunteering shirts sort through boxes."
                    />
                    <HomeCard
                        colour="green"
                        title="Strengthen impact measurement skills"
                        text="Seedkit provides indicators, learning resources and user guidance to improve how you measure and communicate your social enterprise's impact and business success."
                        image="/assets/home_card_2.jpg"
                        imageAlt="Four people working together around a desk to sort donated clothes and food."
                    />
                    <HomeCard
                        colour="green"
                        title="Building public knowledge"
                        text="Seedkit is a powerful source of information about the social enterprise sector. Anonymised and aggregated data are analysed by experts to support strategy and policy advice that helps grow the sector."
                        image="/assets/home_card_3.jpg"
                        imageAlt="Eight people are standing in a circle outside, stacking their hands. They hold cleaning gloves and supplies."
                    />
                </div>
                <div className="flex flex-col items-stretch">
                    <div className="flex flex-col items-stretch gap-[38px] bg-home-steps-mobile bg-right-bottom bg-no-repeat px-5">
                        <div className="flex flex-col items-start gap-4">
                            <h2 className="font-vg-medium text-black text-4xl" dangerouslySetInnerHTML={{__html: "Seedkit works in<br/>4 easy steps"}}></h2>
                        </div>
                        <div className="flex flex-col items-stretch gap-8 mb-8">
                            <HomeStep
                            number="01"
                            title="You register"
                            text="Set up an organisation account and register one or more social enterprises."
                            />
                            <HomeStep
                            number="02"
                            title="Choose indicators"
                            text="Select or create indicators to manage your operations and report on how your social enterprise is changing the world."
                            />
                            <HomeStep
                            number="03"
                            title="Enter data"
                            text="Define and submit data on indicators you want to measure regularly."
                            />
                            <HomeStep
                            number="04"
                            title="Communicate impact"
                            text="Create and export reports from dashboards and share them with stakeholders."
                            />
                        </div>
                    </div>
                    <div className="flex flex-col items-stretch gap-8 bg-grey03 bg-home-faq-mobile bg-right-bottom bg-no-repeat px-5 py-16">
                        <h3 className="font-vg-medium text-black text-[28px] text-center">Frequently asked questions</h3>
                        <div className="flex flex-col items-stretch gap-4">
                            <FaqSet/>
                        </div>
                    </div>
                </div>
                </div>
                <div className="flex flex-col items-stretch gap-6 px-5">
                <Testimonial
                    quote={testimonials[testimonialIndex1].quote}
                    name={testimonials[testimonialIndex1].name}
                    role={testimonials[testimonialIndex1].role}
                    logo={getLogo(testimonials[testimonialIndex1].key)}
                    logoAlt={testimonials[testimonialIndex1].logoAlt}
                    avatar={getAvatar(testimonials[testimonialIndex1].key)}
                    avatarAlt={testimonials[testimonialIndex1].avatarAlt}
                />
                <Testimonial
                    quote={testimonials[testimonialIndex2].quote}
                    name={testimonials[testimonialIndex2].name}
                    role={testimonials[testimonialIndex2].role}
                    logo={getLogo(testimonials[testimonialIndex2].key)}
                    logoAlt={testimonials[testimonialIndex2].logoAlt}
                    avatar={getAvatar(testimonials[testimonialIndex2].key)}
                    avatarAlt={testimonials[testimonialIndex2].avatarAlt}
                />
                </div>
                {articleState.loaded === true && (
                <div className="flex flex-col items-stretch gap-6 py-8 px-5">
                <div className="flex flex-col items-stretch gap-3">
                    <h3 className="font-vg-medium text-black text-[28px]">Impact measurement news and resources</h3>
                    <p className="font-vg-book text-black text-base leading-130">Access product updates, learning resources, events and more.</p>
                </div>
                <HomeResourceBlock
                    type={articleState.news.resourceType}
                    tags={[{ text: "News", color: "green" }]}
                    image={articleState.news.heroImage.largeUrl}
                    title={articleState.news.title}
                    text={articleState.news.lead}
                    link={`/resources/${articleState.news.key}`}
                />
                <HomeResourceBlock
                    type={articleState.news.resourceType}
                    tags={[{ text: "Learn", color: "green" }]}
                    image={articleState.learn.heroImage.largeUrl}
                    title={articleState.learn.title}
                    text={articleState.learn.lead}
                    link={`/resources/${articleState.learn.key}`}
                />
                </div>
                )}
                <div ref={subscribeFormRef}>
                <SubscribeForm/>
                </div>
                <LogoBlock/>
                <Footer className="mx-5"/>
            </div>
        )}
        </>
    );
}