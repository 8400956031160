import { useState, useContext, useEffect, useRef, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { cloneDeep } from 'lodash';

import UiUtils from '../../utils/UiUtils';
import UnitUtils from '../../utils/UnitUtils';
import DataUtils from '../../utils/DataUtils';
import * as GeneralConstants from '../../constants/GeneralConstants';
import * as UiConstants from '../../constants/UiConstants';
import * as EntityConstants from '../../constants/EntityConstants';
import * as DataConstants from '../../constants/DataConstants';
import * as UserConstants from '../../constants/UserConstants';
import OrgService from '../../services/OrgService';
import IndicatorService from '../../services/IndicatorService';
import { GlobalContext } from '../../context/GlobalContext';

import KitSideNav from '../../components/KitSideNav';
import KitTopBar from '../../components/KitTopBar';
import Button from '../../components/Button';
import SelectDropdown from '../../components/SelectDropdown';
import CustomReactDatePicker from '../../components/CustomReactDatePicker';
import Footer from '../../components/Footer';
import DataPeriodModal from '../../components/DataPeriodModal';
import DataIndicatorModal from '../../components/DataIndicatorModal';
import DataDetailsCellRenderer from '../../components/DataDetailsCellRenderer';
import DataTitleCellRenderer from '../../components/DataTitleCellRenderer';
import DataCustomHeader from '../../components/DataCustomHeader';
import ResizableGridContainer from '../../components/ResizableGridContainer';
import DataExcelDownloadModal from '../../components/DataExcelDownloadModal';
import DataExcelUploadModal from '../../components/DataExcelUploadModal';
import TrialUpgradeModal from '../../components/TrialUpgradeModal';
import TermsAgreementModal from '../../components/TermsAgreementModal';

export default function DataManagementPage() {
    const {context, setContextValues} = useContext(GlobalContext);

    const [navExpanded, setNavExpanded] = useState(context.hasOwnProperty('expandedSideNav') ? context.expandedSideNav : true);

    const containerClass = "flex flex-col items-stretch h-screen shrink grow overflow-y-scroll";

    /* Role Access Code */

    const roles = useMemo(() => context.user && context.user.roles ? context.user.roles : [], [context]);
    const [userCanEdit, setUserCanEdit] = useState(UiUtils.checkUserAccess(roles, UserConstants.ObjectTypes.DATA, UserConstants.AccessTypes.EDIT));

    const gridRef = useRef(null);

    const [entity, setEntity] = useState(null);

    const [selectedTypeOption, setSelectedTypeOption] = useState(UiConstants.EMPTY_OPTION);
    
    const [unitOptions, setUnitOptions] = useState([]);
    const [selectedUnitOption, setSelectedUnitOption] = useState(UiConstants.EMPTY_OPTION);

    const [pathwayOptions, setPathwayOptions] = useState([]);
    const [selectedPathwayOption, setSelectedPathwayOption] = useState(UiConstants.EMPTY_OPTION);
    const [pathway, setPathway] = useState(null);

    const [freqOptions, setFreqOptions] = useState([]);
    const [selectedFreqOption, setSelectedFreqOption] = useState(UiConstants.EMPTY_OPTION);
    const [frequency, setFrequency] = useState("");

    const [indicators, setIndicators] = useState([]);

    const [startDate, setStartDate] = useState(null);

    const [columnDefs, setColumnDefs] = useState([]);
    const [rowData, setRowData] = useState([]);
    const [allEdit, setAllEdit] = useState(false);

    const getRowStyle = (params) => {
        if (params.data && params.data.rowType && params.data.rowType === "RequiredData") {
            return { backgroundColor: "#FFFFFF" };
        } else {
            return { backgroundColor: "#F9FAFB" };
        }
    };
    
    /* Data Period Modal Functions */
    
    const [periodModalOpen, setPeriodModalOpen] = useState(false);
    const [periodModalFrequency, setPeriodModalFrequency] = useState(DataConstants.FrequencyType.NONE);

    const createNewPeriod = (details) => {
        let gridData = { rowData: rowData, columnDefs: columnDefs };
        let newGridData = DataUtils.addPeriodDataColumn(gridData, details.date, details.frequency, allEdit);
        setColumnDefs(newGridData.columnDefs);
        setRowData(newGridData.rowData);
        updateEntityColumnDefs(details.frequency, entity, newGridData.columnDefs);
    }

    /* Data Indicator Modal Functions */
    
    const [indModalOpen, setIndModalOpen] = useState(false);
    const [indModalParams, setIndModalParams] = useState({});

    const openIndicatorModal = (key) => {
        const index = entity.indicators.map(i => i.key).indexOf(key);
        if (index > -1) {
            setIndModalParams({
                indicator: entity.indicators[index]
            });
            setIndModalOpen(true);
        }
    }

    const saveIndicatorDetails = (details) => {
        const payload = {
            entityId: entity._id,
            key: details.key,
            source: details.source,
            notes: details.notes
        }
        IndicatorService.updateIndicator(payload)
        .then(response => {
            updateEntity(response.data.entity);
        })
        .catch(err => {
            console.log(err);
            alert("Unable to save indicator details. Please try again later.");
        });
    }

    /* Selection Dropdown Functions */

    const clearContextSelection = () => {
        let selections = context.selections ? cloneDeep(context.selections) : {};
        delete selections.type;
        delete selections.enterprise;
        delete selections.program;
        delete selections.project;
        setContextValues([{
            key: "selections", value: selections
        }]);
    }

    const setContextSelection = (type, id) => {
        let selections = context.selections ? cloneDeep(context.selections) : {};
        delete selections.enterprise;
        delete selections.program;
        delete selections.project;
        selections.type = type;
        selections[type] = {
            id: id,
            edit: true
        }
        setContextValues([{
            key: "selections", value: selections
        }]);
    }

    const getEntity = (type, id) => {
        let unit = null;
        let index = -1;
        switch (type) {
            case EntityConstants.EntityType.ENTERPRISE:
                index = context.organisation.enterprises.map(e => e._id).indexOf(id);
                if (index > -1) {
                    unit = context.organisation.enterprises[index];
                }
                break;
            case EntityConstants.EntityType.PROGRAM:
                index = context.organisation.programs.map(p => p._id).indexOf(id);
                if (index > -1) {
                    unit = context.organisation.programs[index];
                }
                break;
            case EntityConstants.EntityType.PROJECT:
                index = context.organisation.projects.map(p => p._id).indexOf(id);
                if (index > -1) {
                    unit = context.organisation.projects[index];
                }
                break;
            default:
                break;
        }
        return unit;
    }

    const updateEntity = (newEntity) => {
        let organisation = cloneDeep(context.organisation);
        let index = -1;
        switch (newEntity.entityType) {
            case EntityConstants.EntityType.ENTERPRISE:
            case EntityConstants.EntityType.SOCIAL_ENTERPRISE:
                index = organisation.enterprises.map(e => e._id).indexOf(newEntity._id);
                if (index > -1) {
                    organisation.enterprises[index] = newEntity;
                }
                break;
            case EntityConstants.EntityType.PROGRAM:
                index = organisation.programs.map(p => p._id).indexOf(newEntity._id);
                if (index > -1) {
                    organisation.programs[index] = newEntity;
                }
                break;
            case EntityConstants.EntityType.PROJECT:
                index = organisation.projects.map(p => p._id).indexOf(newEntity._id);
                if (index > -1) {
                    organisation.projects[index] = newEntity;
                }
                break;
            default:
                break;
        }
        setEntity(newEntity);
        setContextValues([{ key: "organisation", value: organisation }]);
    }

    const updateEntityData = (newData) => {
        const newEntityData = DataUtils.updateEntityData(entity.data, newData);
    
        OrgService.updateEntityData(entity._id, newEntityData)
        .then(response => {
            updateEntity(response.data.entity);
        })
        .catch(err => {
            console.log(err);
        });
    }

    const getPathwayOptions = (newEntity) => {
        let options = newEntity.pathways ? newEntity.pathways.map(e => {
            return { value: e._id, label: e.title };
        }) : [];
        options.sort((a, b) => {
            if (a.title < b.title) {
                return -1;
            } else if (a.title > b.title) {
                return 1;
            } else {
                return 0;
            }
        });
        return options.length > 0 ? [UiConstants.EMPTY_OPTION].concat(options) : [];
    }

    const getPathway = (ent, pathwayId) => {
        const pathwayIndex = ent.pathways && pathwayId !== "" ? ent.pathways.map(p => p._id).indexOf(pathwayId) : -1;
        if (pathwayIndex > -1) {
            return ent.pathways[pathwayIndex];
        } else {
            return null;
        }
    }

    const getFreqOptions = (pathway) => {
        if (pathway) {
            const indicatorFreqs = entity.indicators ? entity.indicators.filter(ind1 => pathway.indicators.indexOf(ind1.key) > -1).map(ind2 => ind2.frequency) : [];
            let options = [];
            for (let i = 1; i < UiConstants.FREQUENCY_OPTIONS.length; i++) {
                const opt = UiConstants.FREQUENCY_OPTIONS[i];
                if (indicatorFreqs.indexOf(opt.value) > -1) {
                    options.push(opt);
                }
            }
            return options.length > 0 ? [UiConstants.EMPTY_OPTION].concat(options) : [];
        } else {
            return [];
        }
    }

    const getIndicators = (pathway, freq) => {
        return entity.indicators && freq !== "" && pathway ? entity.indicators.filter(ind => pathway.indicators.indexOf(ind.key) > -1 && ind.frequency === freq) : [];
    } 

    const getEntityColumnDefs = (freq, ent) => {
        if (ent !== null) {
            const index = ent.dataColumns.map(dc => dc.interval).indexOf(freq);
            if (index > -1) {
                let dataColumns = entity.dataColumns[index].columnDefs;
                dataColumns = dataColumns.filter(dc => dc.field !== "");
                return dataColumns;
            } else {
                return [];
            }
        } else {
            return [];
        }
    }

    const updateEntityColumnDefs = (freq, ent, coldefs) => {
        let newColDefs = cloneDeep(coldefs);
        newColDefs = newColDefs.filter(cd => cd.field !== "");
        const titleIndex = newColDefs.map(cd => cd.field).indexOf("title");
        if (titleIndex > -1) {
            let titleColDef = cloneDeep(newColDefs[titleIndex]);
            delete titleColDef.cellRenderer;
            newColDefs[titleIndex] = titleColDef;
        }
        const newDataColumn = {
            interval: freq,
            columnDefs: newColDefs
        };

        let newEntity = cloneDeep(ent);
        if (newEntity.dataColumns) {
            const cdIndex = newEntity.dataColumns.map(dc => dc.interval).indexOf(freq);
            if (cdIndex > -1) {
                newEntity.dataColumns[cdIndex] = newDataColumn;
            } else {
                newEntity.dataColumns.push(newDataColumn);
            }
        } else {
            newEntity.dataColumns = [newDataColumn];
        }
        updateEntity(newEntity);
        
        OrgService.updateEntityDataColumns(newEntity._id, newEntity.dataColumns)
        .then(response => {
            //console.log("updateEntityDataColumns", response);
        })
        .catch(err => {
            console.log(err);
        });
    }

    const reorderColumnDefs = (freq, ent) => {
        let newColDefs = [];
        if (freq !== "" && ent != null) {
            let colDefs = getEntityColumnDefs(freq, ent);
            let dateColDefs = [];
            for (let i = 0; i < colDefs.length; i++) {
                let colDef = colDefs[i];
                if (colDef.field.startsWith("date")) {
                    dateColDefs.push(colDef);
                } else {
                    newColDefs.push(colDef);
                }
            }
            dateColDefs.sort((a, b) => {
                if (a.field > b.field) {
                    return -1;
                } else if (a.field < b.field) {
                    return 1;
                } else {
                    return 0;
                }
            });
            newColDefs = newColDefs.concat(dateColDefs);
            newColDefs = newColDefs.filter(cd => cd.field !== "");
        }
        return newColDefs;
    }

    const clearGrid = () => {
        setColumnDefs([]);
        setRowData([]);
    }

    const setGrid = (freq, ent, inds, start, allEditFlag, doReorder, forceNewDates) => {
        if (freq !== "" && ent !== null) {
            let colDefs = [];
            let forceColUpdate = false;
            if (doReorder) {
                colDefs = reorderColumnDefs(freq, ent);
                forceColUpdate = true;
            } else {
                colDefs = getEntityColumnDefs(freq, ent);
            }
            let options = {
                editAll: allEditFlag,
                userCanEdit: userCanEdit,
                forceNewDates: forceNewDates
            }
            let gdata = DataUtils.gridColsDataForIndicators(ent, ent.data, colDefs, inds, freq, start, null, options);
            // Set Column Defs with CellRenderers
            let newColDefs = gdata.columnDefs.filter(cd => cd.field !== "");
            const titleIndex = newColDefs.map(cd => cd.field).indexOf("title");
            if (titleIndex > -1) {
                newColDefs[titleIndex].cellRenderer = DataTitleCellRenderer;
            }
            newColDefs.push({
                field: "",
                pinned: "right",
                width: 50,
                cellRenderer: DataDetailsCellRenderer,
                cellRendererParams: {
                    clicked: (key) => openIndicatorModal(key)
                },
                cellStyle: (_) => {
                    return { padding: 0 };
                }
            });
            setColumnDefs(newColDefs);
            setRowData(gdata.rowData);
            if (gdata.colDefsUpdated === true || forceColUpdate === true) {
                updateEntityColumnDefs(freq, ent, newColDefs);
            }
        } else {
            setColumnDefs([]);
            setRowData([]);
        }
    }

    const onTypeChange = (selection) => {
        setSelectedTypeOption(selection);
        setUnitOptions(UnitUtils.getUnitOptionsByType(context, selection.value, false));
        setSelectedUnitOption(UiConstants.EMPTY_OPTION);

        setPathwayOptions([]);
        setSelectedPathwayOption(UiConstants.EMPTY_OPTION);
        setPathway(null);

        setFreqOptions([]);
        setSelectedFreqOption(UiConstants.EMPTY_OPTION);
        setFrequency("");

        setIndicators([]);

        clearContextSelection();
        clearGrid();
    }

    const onUnitChange = (selection) => {
        setSelectedUnitOption(selection);
        const newEntity = getEntity(selectedTypeOption.value, selection.value);

        setPathwayOptions(getPathwayOptions(newEntity));
        setSelectedPathwayOption(UiConstants.EMPTY_OPTION);
        setPathway(null);

        setFreqOptions([]);
        setSelectedFreqOption(UiConstants.EMPTY_OPTION);
        setFrequency("");

        setIndicators([]);

        setEntity(newEntity);
        setContextSelection(selectedTypeOption.value, selection.value);
        clearGrid();
    }

    const onPathwayChange = (selection) => {
        setSelectedPathwayOption(selection);
        const newPathway = getPathway(entity, selection.value);
        setPathway(newPathway);

        setFreqOptions(getFreqOptions(newPathway));
        setSelectedFreqOption(UiConstants.EMPTY_OPTION);
        setFrequency("");

        setIndicators([]);

        clearGrid();
    }

    const onFrequencyChange = (selection) => {
        setSelectedFreqOption(selection);
        setFrequency(selection.value);
        
        const inds = getIndicators(pathway, selection.value);
        setIndicators(inds);

        setGrid(selection.value, entity, inds, startDate, allEdit, true, false);
    }

    const onStartDateChange = (selection) => {
        setStartDate(selection);
        setGrid(frequency, entity, indicators, selection, allEdit, false, false);
    }

    const onColumnAddClick = () => {
        if (frequency !== "") {
            setPeriodModalFrequency(frequency);
            setPeriodModalOpen(true);
        }
    }

    const onAllEditToggle = () => {
        const allEditFlag = !allEdit;
        setAllEdit(allEditFlag);
        setGrid(frequency, entity, indicators, startDate, allEditFlag, false, false);
    }

    const onDateReset = () => {
        setStartDate(null);
        setGrid(frequency, entity, indicators, null, allEdit, false, false);
    }

    /* Grid Interaction Functions */

    const validateCellValue = (value, format) => {
        return format === "*" || !isNaN(value);
    }

    const formatNewValue = (value, format) => {
        return format === "*" ? value.toString() : parseFloat(value);
    }

    const onGridReady = (params) => {
        params.api.setRowData([]);
        params.api.setColumnDefs([]);
    }

    const onCellValueChanged = (params) => {
        const rowIndex = params.rowIndex;
        const field = params.colDef.field
        const newValue = params.newValue;
        const oldValue = params.oldValue;
        const format = rowData[rowIndex].format;
        let newRowData;
        if (validateCellValue(params.newValue, format)) {
            newRowData = DataUtils.updateRowData(rowData, rowIndex, field, formatNewValue(newValue, format));
            const preparedData = DataUtils.prepareColumnDataForSave(newRowData, field);
            updateEntityData(preparedData);
        } else {
            alert("This is not a valid value for this type of data");
            newRowData = cloneDeep(rowData);
            newRowData[rowIndex][field] = oldValue;
        }
        setRowData(newRowData);
    } 

    const getRowNodes = () => {
        let rowNodes = [];
        if (gridRef.current && gridRef.current.api) {
            gridRef.current.api.forEachNode(node => rowNodes.push(node));
        }
        return rowNodes;
    }

    const getAllEditableCells = () => {
        let editableCells = [];
        if (gridRef.current && gridRef.current.api) {
            const dataColumns = gridRef.current.api.columnModel.getAllDisplayedColumns().filter(col => col.colId.startsWith("date"));
            const rowNodes = getRowNodes();
            dataColumns.forEach(dataCol => {
                for (let i = 0; i < rowNodes.length; i++) {
                if (dataCol.isCellEditable(rowNodes[i])) {
                    editableCells.push({
                        rowIndex: i,
                        column: dataCol
                    });
                }
                }
            })
        }
        return editableCells;
    }

    const getCellIndex = (cells, column, row) => {
        let index = -1;
        for (let i = 0; i < cells.length; i++) {
            if (cells[i].rowIndex === row && cells[i].column.colId === column.colId) {
                index = i;
                break;
            }
        }
        return index;
    }

    const getNextEditableCell = (startColumn, startIndex) => {
        const editableCells = getAllEditableCells();
        const cellIndex = getCellIndex(editableCells, startColumn, startIndex);
        if (cellIndex > -1) {
            if (cellIndex < editableCells.length - 1) {
                return editableCells[cellIndex + 1];
            } else {
                return editableCells[0];
            }
        } else {
            return editableCells[0];
        }
    }

    const getPrevEditableCell = (startColumn, startIndex) => {
        const editableCells = getAllEditableCells();
        const cellIndex = getCellIndex(editableCells, startColumn, startIndex);
        if (cellIndex > -1) {
            if (cellIndex > 0) {
                return editableCells[cellIndex - 1];
            } else {
                return editableCells[editableCells.length - 1];
            }
        } else {
            return editableCells[0];
        }
    }

    const tabToNextCell = (params) => {
        if (params.editing) {
            const currentCell = params.previousCellPosition;
            let nextCell;
            if (params.backwards) {
                // Previous
                nextCell = getPrevEditableCell(currentCell.column, currentCell.rowIndex);
            } else {
                // Next
                nextCell = getNextEditableCell(currentCell.column, currentCell.rowIndex);
            }
            return {
                rowIndex: nextCell.rowIndex,
                column: nextCell.column
            }
        } else {
            return params.nextCellPosition;
        }
    }

    /* Download Excel Template Functions */
    
    const [excelDownModalOpen, setExcelDownModalOpen] = useState(false);
    const [excelDownModalParams, setExcelDownModalParams] = useState({});

    const onDownloadTemplateClick = () => {
        if (context.user && context.user.trialAccount === true) {
            alert("You cannot access this functionality as you are currently on a trial account.");
            return;
        }
        let params = {
            organisation: context.organisation,
            type: selectedTypeOption ? selectedTypeOption.value : "",
            unit: selectedUnitOption ? selectedUnitOption.value : "",
            pathway: selectedPathwayOption ? selectedPathwayOption.value : "",
            frequency: frequency ? frequency : ""
        }
        setExcelDownModalParams(params);
        setExcelDownModalOpen(true);
    }

    /* Upload Excel Template Functions */
    
    const [excelUpModalOpen, setExcelUpModalOpen] = useState(false);
    const [excelUpModalParams, setExcelUpModalParams] = useState({});

    const onUploadTemplateClick = () => {
        if (context.user && context.user.trialAccount === true) {
            alert("You cannot access this functionality as you are currently on a trial account.");
            return;
        }
        let params = {
            organisation: context.organisation,
            type: selectedTypeOption ? selectedTypeOption.value : "",
            unit: selectedUnitOption ? selectedUnitOption.value : ""
        }
        setExcelUpModalParams(params);
        setExcelUpModalOpen(true);
    }

    const cleanImportData = (entity, details) => {
        let cleanData = [];
        details.data.forEach(d => {
            const rdIndex = entity.requiredData.map(rd => rd.key).indexOf(d.key);
            if (rdIndex > -1) {
                const requiredData = entity.requiredData[rdIndex];
                if (d.value.toString().trim() !== "") {
                    const use = isNaN(d.value) ? (requiredData.format === "*") : true;
                    if (use) {
                        cleanData.push(d);
                    }
                }
            }
        });
        return cleanData;
    }

    const consolidateData = (entity, data) => {
        let consData = cloneDeep(entity.data);
        data.forEach(d1 => {
            for (let i = 0; i < consData.length; i++) {
                const d2 = consData[i]; 
                if (d2.key === d1.key && DataUtils.getUtcDate(d2.utcDate).isSame(DataUtils.getUtcDate(d1.utcDate)) && d2.interval === d1.interval) {
                    consData[i].value = d1.value;
                }
            }
        });
        return consData;
    }

    const onExcelImport = (details) => {
        let entity = getEntity(details.entityType, details.entity);
        const cleanData = cleanImportData(entity, details);
        if (cleanData.length === 0) {
            alert("There is no data to import from this spreadsheet.");
            return;
        }
        let consData = consolidateData(entity, cleanData);
        const data = DataUtils.postImportRecalc(entity, consData);
        OrgService.updateEntityData(details.entity, data)
        .then(response => {
            const typeIndex = UiConstants.UNIT_TYPE_OPTIONS.map(t => t.value).indexOf(details.entityType);
            setSelectedTypeOption(typeIndex > -1 ? UiConstants.UNIT_TYPE_OPTIONS[typeIndex] : UiConstants.EMPTY_OPTION);
            const unitOpts = UnitUtils.getUnitOptionsByType(context, details.entityType, false)
            setUnitOptions(unitOpts);
            const unitIndex = unitOpts.map(u => u.value).indexOf(details.entity);
            setSelectedUnitOption(unitIndex > -1 ? unitOpts[unitIndex] : UiConstants.EMPTY_OPTION);

            const pathwayOpts = getPathwayOptions(response.data.entity);
            setPathwayOptions(pathwayOpts);
            const newPathway = getPathway(response.data.entity, details.pathwayId);
            setPathway(newPathway);
            const pathwayIndex = pathwayOpts.map(p => p.value).indexOf(details.pathwayId);
            setSelectedPathwayOption(pathwayIndex > -1 ? pathwayOpts[pathwayIndex] : UiConstants.EMPTY_OPTION);

            const freqIndex = UiConstants.FREQUENCY_OPTIONS.map(f => f.value).indexOf(details.interval);
            setSelectedFreqOption(freqIndex > -1 ? UiConstants.FREQUENCY_OPTIONS[freqIndex] : UiConstants.EMPTY_OPTION);
            setFrequency(freqIndex > -1 ? details.interval : "");

            const ent = getEntity(details.entityType, details.entity);
            let inds = [];
            let indOptions = [];
            if (ent) {
                for (let i = 0; i < ent.indicators.length; i++) {
                    const ind = ent.indicators[i];
                    let useInd = false;
                    ind.requiredData.forEach(rd => {
                        const rdi = details.keys.indexOf(rd);
                        if (rdi > -1) {
                            useInd = true;
                        }
                    });
                    if (useInd && inds.map(j => j.value).indexOf(ind._id) === -1) {
                        inds.push(ind);
                        indOptions.push({ value: ind._id, label: ind.title });
                    }
                }
            }
            setIndicators(inds);
            setStartDate(null);
            updateEntity(response.data.entity);
            setExcelUpModalOpen(false);

            setGrid(details.interval, response.data.entity, inds, null, allEdit, true, true);
        })
        .catch(err => {
            console.log(err);
            alert("Unable to upload data. Please try again later");
        });
    }

    /* Dropdown Updates Functions */

    useEffect(() => {
        const selUnit = UnitUtils.getSelectedUnit(context);
        if (selUnit.type !== EntityConstants.EntityType.NONE && pathway === null) {
            setSelectedTypeOption(UnitUtils.getTypeFromSelectedUnit(selUnit));

            setUnitOptions(UnitUtils.getUnitOptionsFromContext(context, selUnit, false));
            setSelectedUnitOption(UnitUtils.getSelectedUnitOptionFromContext(context, selUnit, false));

            const newEntity = UnitUtils.getSelectedEntity(context, selUnit);

            setPathwayOptions(getPathwayOptions(newEntity));
            setSelectedPathwayOption(UiConstants.EMPTY_OPTION);
            setPathway(null);
    
            setFreqOptions([]);
            setSelectedFreqOption(UiConstants.EMPTY_OPTION);
            setFrequency("");
    
            setIndicators([]);
    
            setEntity(newEntity);
        }
    }, [context, pathway]);

    /* Data Update Function */

    useEffect(() => {
        if (gridRef.current && gridRef.current.api) {
            let currentData = [];
            gridRef.current.api.forEachNode(node => currentData.push(node.data));
            let oldEditColumn = "";
            let newEditColumn = "";
            if (currentData.length > 0 && rowData.length > 0 && currentData.length === rowData.length) {
                let hasFocus = false;
                let rowIndex = -1;
                let colKey = null;
                let focusedCell = gridRef.current.api.getFocusedCell();
                if (focusedCell) {
                    hasFocus = true;
                    rowIndex = focusedCell.rowIndex;
                    colKey = focusedCell.column.colId;
                    gridRef.current.api.clearFocusedCell();
                }
                gridRef.current.api.forEachNode((node, index) => {
                if (index < rowData.length) {
                    if (oldEditColumn === "") {
                        if (node.data.editColumn) {
                            oldEditColumn = node.data.editColumn;
                        }
                    }
                    if (newEditColumn === "") {
                        if (rowData[index].editColumn) {
                            newEditColumn = rowData[index].editColumn;
                        }
                    }
                    node.updateData(rowData[index]);
                }
                });
                if (hasFocus && oldEditColumn === newEditColumn) { 
                gridRef.current.api.startEditingCell({ rowIndex : rowIndex, colKey: colKey });
                }
            } else {
                gridRef.current.api.setRowData(rowData);
            }
            gridRef.current.api.setColumnDefs(columnDefs);
            if (oldEditColumn !== newEditColumn) {
                gridRef.current.api.redrawRows();
            }
        }
    }, [gridRef, columnDefs, rowData]);

    /* Trial Upgrade Modal Functions */

    const [upgradeModalOpen, setUpgradeModalOpen] = useState(false);

    const [isTrial, setIsTrial] = useState(context.user && context.user.trialAccount ? context.user.trialAccount : false);

    const onTrialUpgradeClick = () => {
        setUpgradeModalOpen(true);
    }

    const onTrialUpgradeDone = () => {
        setIsTrial(false);
        setUpgradeModalOpen(false);
    }
    
    /* Data Refresh Function */

    const [dataRefresh, setDataRefresh] = useState(false);
    
    useEffect(() => {
        if (dataRefresh) {
            setUserCanEdit(UiUtils.checkUserAccess(roles, UserConstants.ObjectTypes.DATA, UserConstants.AccessTypes.EDIT));
            setIsTrial(context.user && context.user.trialAccount ? context.user.trialAccount : false);
            setDataRefresh(false);
        }
    }, [dataRefresh, context, setDataRefresh, roles]);

    /* Terms Agreement Functions */

    const [termsModalOpen, setTermsModalOpen] = useState(false);

    useEffect(() => {
        if (context.user && context.user.id) {
            if (context.user.agreeToTerms && context.user.agreeToTerms === true) {
                setTermsModalOpen(false);
            } else {
                setTermsModalOpen(true);
            }
        } else {
            setTermsModalOpen(false);
        }
    }, [context, setTermsModalOpen]);

    /* Matomo Tracking Code */

    useEffect(() => {
        const title = 'Data Management';
        document.title = title;
        var _mtm = window._mtm = window._mtm || [];
        _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
        _mtm.push({'setDocumentTitle': title });
        var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
        g.async=true; g.src='https://matomo-staging.seedkit.com.au/js/container_VevA4SEN.js'; s.parentNode.insertBefore(g,s);
    }, []);
    
    return(
        <div className="w-full h-full flex">
            <KitSideNav page="Data Management" onToggle={(value) => setNavExpanded(value)}/>
            <div className={UiUtils.classNames(containerClass, navExpanded ? GeneralConstants.EXPANDED_NAV_MARGIN : GeneralConstants.COLLAPSED_NAV_MARGIN)}>
                {isTrial ? (
                <KitTopBar 
                    onDataRefresh={() => setDataRefresh(true)} 
                    banner={GeneralConstants.TRIAL_BANNER}
                    onBannerClick={() => onTrialUpgradeClick()}
                />
                ) : (
                <KitTopBar onDataRefresh={() => setDataRefresh(true)}/>
                )}
                <div className="flex flex-col items-stretch gap-10 py-8 px-10 bg-white">
                <div className="flex flex-col items-stretch gap-10">
                    <div className="flex flex-col items-stretch gap-3">
                        <div className="flex justify-between items-center">
                            <h4 className="font-vg-medium text-black text-3.5xl leading-110">Data Management</h4>
                            {userCanEdit && (
                            <div className="flex items-center gap-6">
                                <Button variant="outline" size="large" label="Download Template" onClick={onDownloadTemplateClick}/>
                                <Button variant="outline" size="large" label="Upload Template" onClick={onUploadTemplateClick}/>
                            </div>
                            )}
                        </div>
                        <div className="grid grid-cols-2 w-full items-stretch gap-x-6 gap-y-3 px-6 py-4 rounded-lg bg-grey04 border border-grey03">
                            <div className="col-span-2">
                            <h6 className="font-vg-medium text-xl text-black">Select the reporting unit and indicators you wish to view or enter data for:</h6>
                            </div>
                            <SelectDropdown
                            label="Select Type"
                            options={UiConstants.UNIT_TYPE_OPTIONS}
                            selectedOption={selectedTypeOption || UiConstants.EMPTY_OPTION}
                            onChange={onTypeChange}
                            />
                            <SelectDropdown
                            label={selectedTypeOption.label ? `Select ${selectedTypeOption.label}` : "Select Reporting Unit"}
                            options={unitOptions ? unitOptions : []}
                            selectedOption={selectedUnitOption || UiConstants.EMPTY_OPTION}
                            onChange={onUnitChange}
                            />
                            <SelectDropdown
                            label="Select Impact Pathway"
                            options={pathwayOptions ? pathwayOptions : []}
                            selectedOption={selectedPathwayOption || UiConstants.EMPTY_OPTION}
                            onChange={onPathwayChange}
                            />
                            <SelectDropdown
                            label="Select Data Entry Interval"
                            options={freqOptions}
                            selectedOption={selectedFreqOption || UiConstants.EMPTY_OPTION}
                            tooltip="Filter pathway indicators by how often you are reporting data for them."
                            onChange={onFrequencyChange}
                            />
                            <div className="col-span-2">
                            <p className="font-vg-regular text-base">
                                <a href="/resources/selected-indicators" className="text-blue no-underline" target="_blank" rel="noopener noreferrer">Learn more about how to add data to indicators</a>
                            </p>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col items-stretch gap-6 rounded-lg p-6 bg-grey04 border border-grey03">
                        <div className="flex items-center justify-between">
                            <div className="flex flex-col items-start gap-2">
                            <h6 className="font-vg-medium text-xl text-black">
                                    {frequency === "" ? "Required / Indicator Data" : `${frequency} Required / Indicator Data`}
                            </h6>
                            <p className="font-vg-regular text-base my-2">
                                    <a href="/resources/required-indicator-data" className="text-blue no-underline" target="_blank" rel="noopener noreferrer">Learn more about required and indicator data</a>
                            </p>
                            {userCanEdit && (
                                    <div className="flex items-center gap-6">
                                        <Button 
                                        variant="outline" 
                                        size="small" 
                                        label="Add Data" 
                                        rightIcon="ChevronDoubleDownIcon"
                                        onClick={onColumnAddClick} 
                                        />
                                        <Button 
                                        variant="outline"
                                        size="small"
                                        label={allEdit ? "Edit Latest Only" : "Edit All"}
                                        leftIcon="PencilAltIcon"
                                        onClick={onAllEditToggle}
                                        />
                                    </div>
                            )}
                            </div>
                            <div className="flex items-end gap-3">
                            <CustomReactDatePicker
                                    label="See Data Going Back To"
                                    value={startDate}
                                    onChange={onStartDateChange}
                            />
                            <Button
                                    variant="outline"
                                    size="small"
                                    label="Reset Date"
                                    onClick={onDateReset}
                            />
                            </div>
                        </div>
                        <ResizableGridContainer 
                            className="ag-theme-alpine compact" 
                            height={500}
                        >
                            <AgGridReact
                            ref={gridRef}
                            singleClickEdit={true}
                            suppressScrollOnNewData={true}
                            components={{
                                agColumnHeader: DataCustomHeader
                            }}
                            getRowStyle={getRowStyle}
                            onGridReady={onGridReady}
                            onCellValueChanged={onCellValueChanged}
                            tabToNextCell={tabToNextCell}
                            />
                        </ResizableGridContainer>
                    </div>
                </div>
                <Footer/>
                </div>
            </div>
            <DataPeriodModal
                frequency={periodModalFrequency} 
                open={periodModalOpen}
                onSet={(details) => createNewPeriod(details)} 
                onClose={() => setPeriodModalOpen(false)}
            />
            <DataIndicatorModal
                params={indModalParams} 
                open={indModalOpen}
                onSave={(details) => saveIndicatorDetails(details)} 
                onClose={() => setIndModalOpen(false)}
            />
            <DataExcelDownloadModal
                params={excelDownModalParams} 
                open={excelDownModalOpen}
                onClose={() => setExcelDownModalOpen(false)}
            />
            <DataExcelUploadModal
                params={excelUpModalParams} 
                open={excelUpModalOpen}
                onImport={(details) => onExcelImport(details)}
                onClose={() => setExcelUpModalOpen(false)}
            />
            <TrialUpgradeModal
                open={upgradeModalOpen}
                onUpgrade={(_) => onTrialUpgradeDone()}
                onClose={() => setUpgradeModalOpen(false)}
            />
            <TermsAgreementModal
                open={termsModalOpen}
                onAgreement={() => setTermsModalOpen(false)}
            />
        </div>
    );
}