import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWindowSize } from 'usehooks-ts';

import AuthService from '../../services/AuthService';
import UiUtils from '../../utils/UiUtils';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Button from '../../components/Button';
import SubscribeForm from '../../components/SubscribeForm';
import LogoBlock from '../../components/LogoBlock';

import AboutPhoto1 from '../../images/about_photo_1.png';
import AboutPhoto2 from '../../images/about_photo_2.jpg';
import AboutPhoto3 from '../../images/about_photo_3.png';
import AboutPhoto4 from '../../images/about_photo_4.png';

export default function AboutPage() {
    const { width } = useWindowSize();
    const isMobile = UiUtils.isMobile(width);
    const isDesktop = !isMobile;

    const navigate = useNavigate();
    
    const loggedIn = AuthService.isLoggedIn();

    function doLogin() {
        if (loggedIn) {
            navigate("/kit/home");
        } else {
            navigate("/login");
        }
    }

    /* Matomo Tracking Code */

    useEffect(() => {
        const title = 'About';
        document.title = title;
        var _mtm = window._mtm = window._mtm || [];
        _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
        _mtm.push({'setDocumentTitle': title });
        var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
        g.async=true; g.src='https://matomo-staging.seedkit.com.au/js/container_VevA4SEN.js'; s.parentNode.insertBefore(g,s);
    }, []);
    
    return(
        <>
        {isDesktop && (
            <div className="box-border w-full h-full py-6 flex flex-col items-stretch gap-10">
                <Header title="About Seedkit" className="mx-16"/>
                <div className="grid grid-cols-2 gap-6 py-10 box-border max-w-content mx-16">
                    <div className="flex flex-col items-start gap-8 grow shrink">
                        <h2 className="font-vg-medium text-black text-5xl">
                            Empowering social enterprises to communicate their impact
                        </h2>
                        <p className="font-vg-book text-black text-xl leading-130">
                            Seedkit helps social enterprises measure and communicate their impact. Seedkit's self-managed reporting platform helps social enterprises 
                            to measure, track and communicate operations and impacts via customised dashboards and reports.
                        </p>
                        <Button variant="solid" size="large" label="LOGIN" className="w-64" onClick={doLogin}/>
                    </div>
                    <div className="grid grid-cols-6 grid-rows-1 gap-6">
                        <img src={AboutPhoto2} className="col-start-2 col-span-5 w-full row-span-1 row-start-1 rounded-2xl" alt="Four people wearing uniforms are working together outside on the grass. They are gardening."/>
                        <img src={AboutPhoto1} className="col-start-1 col-span-3 row-span-1 row-start-1 mt-[243px]" alt=""/>
                    </div>
                </div>
                <div className="box-border w-full flex flex-col items-center py-20 gap-10 bg-b-166-r bg-about-center bg-no-repeat bg-grey03">
                    <div className="grid grid-cols-12 gap-6 py-10 box-border max-w-content px-8 mx-16">
                        <img src={AboutPhoto3} className="col-span-5" alt="Smiling woman standing at a desk in a meeting room with indicator visualisations to the left of her."/>
                        <div className="col-start-7 col-span-6 flex flex-col flex-start gap-4 text-black">
                            <h3 className="font-vg-medium text-[38px]">What is Seedkit?</h3>
                            <p className="font-vg-book text-base">
                                Measuring and communicating impact is difficult but important for many purpose-led organisations. 
                                For social enterprises, it can be even more complex because it is important to report on both your impact and the sustainability 
                                of your business operations. In addition, the impact you are generating together with your business success needs to be reported to 
                                many different stakeholders to provide a full picture of your social enterprise.  
                                <br/><br/>
                                Seedkit solves these problems. It is a self-managed impact reporting and learning tool designed with and for social enterprises. 
                                It enables you to select or create indicators that accurately reflect your social enterprise's impact goals and business operations. 
                                It provides you with a simple and streamlined way of entering and monitoring data for each indicator and it generates 
                                meaningful data dashboards and downloadable reports for stakeholders.
                                <br/><br/>
                                The other benefit of Seedkit is that it can produce insights about the social enterprise sector. The developers of Seedkit are 
                                highly experienced social enterprise researchers who are able to aggregate and analyse de-identified data from Seedkit to generate insights 
                                about the sector and provide social enterprises, policy-makers and funders with the evidence they need to help the sector thrive.   
                            </p>
                        </div>
                    </div>
                    <div className="grid grid-cols-12 gap-6 py-10 box-border max-w-content px-8 mx-16">
                        <div className="col-span-6 flex flex-col flex-start gap-4 text-black">
                            <h3 className="font-vg-medium text-[38px]">Who is behind Seedkit?</h3>
                            <p className="font-vg-book text-base">
                                Seedkit is owned and hosted by Melbourne Social Equity Institute at the University of Melbourne. 
                                Seedkit was co-designed with a group of social enterprises, including those listed below, who generously shared their time and insights. 
                                Any errors on the website are the responsibility of the Seedkit team. 
                            </p>
                            <ul className="grid grid-cols-2 gap-4 list-disc list-inside">
                                <li className="font-vg-book text-base">Asylum Seeker Resource Centre</li>  
                                <li className="font-vg-book text-base">Aussie Veterans Coffee Co.</li> 
                                <li className="font-vg-book text-base">Bendigo Foodshare</li>  
                                <li className="font-vg-book text-base">Centre for Participation</li>   
                                <li className="font-vg-book text-base">Cirque du Soil</li>   
                                <li className="font-vg-book text-base">CURATE</li> 
                                <li className="font-vg-book text-base">ecoDynamics Nursery</li>   
                                <li className="font-vg-book text-base">Family Life</li>  
                                <li className="font-vg-book text-base">FLOAT</li>   
                                <li className="font-vg-book text-base">For Change Co.</li>  
                                <li className="font-vg-book text-base">Free to Feed</li>  
                                <li className="font-vg-book text-base">Fruit2Work</li> 
                                <li className="font-vg-book text-base">Gippsland Community Radio Society</li>   
                                <li className="font-vg-book text-base">Good Cycles</li>   
                                <li className="font-vg-book text-base">Greatest Good</li>   
                                <li className="font-vg-book text-base">Helm Youth Services</li> 
                                <li className="font-vg-book text-base">Hepburn Energy</li>  
                                <li className="font-vg-book text-base">Homeable</li>  
                                <li className="font-vg-book text-base">Humanitarian Advisory Group</li>    
                                <li className="font-vg-book text-base">Kew Neighbourhood Learning Centre</li>  
                                <li className="font-vg-book text-base">Lively</li>  
                                <li className="font-vg-book text-base">Moral Fairground</li>  
                                <li className="font-vg-book text-base">Mountain Monthly Co-operative</li>   
                                <li className="font-vg-book text-base">Sacred Heart Mission</li>  
                                <li className="font-vg-book text-base">Segue Community Hub & Arts Cafe</li>   
                                <li className="font-vg-book text-base">Servants Community Housing</li>   
                                <li className="font-vg-book text-base">Social Good Outpost</li>  
                                <li className="font-vg-book text-base">STREAT</li>  
                                <li className="font-vg-book text-base">Urban Camp Melbourne Cooperative</li>   
                                <li className="font-vg-book text-base">Wonthaggi Miners Dispensary</li>  
                                <li className="font-vg-book text-base">Yea Community House</li> 
                                <li className="font-vg-book text-base">100 Story Building</li>
                            </ul>
                            <p className="font-vg-book text-base">
                                Seedkit's genesis was in action research conducted through the Social Enterprise Impact Lab, 
                                co-funded by Lord Mayor's Charitable Foundation and Family Life. 
                                The initial development of Seedkit was conducted in partnership with the Centre for Social Impact Swinburne 
                                and funded by Victoria State Government.
                            </p>
                        </div>
                        <img src={AboutPhoto4} className="col-start-7 col-span-6" alt="A video conference call on a laptop screen with a grid of smiling faces visible. To the right hand side of this, is a whiteboard covered with Post-It Notes."/>
                    </div>
                </div>
                <SubscribeForm/>
                <LogoBlock/>
                <Footer className="mx-16"/>
            </div>
        )}
        {isMobile && (
            <div className="box-border w-full h-full py-6 flex flex-col items-stretch gap-8">
                <Header title="About<br/>Seedkit" className="mx-5"/>
                <div className="flex flex-col items-stretch gap-8 py-10 px-5">
                    <h2 className="font-vg-medium text-black text-4xl leading-110">
                        Empowering social enterprises to communicate their impact
                    </h2>
                    <p className="font-vg-book text-black text-xl leading-130">
                        Seedkit helps social enterprises measure and communicate their impact. Seedkit's self-managed reporting platform helps social enterprises 
                        to measure, track and communicate operations and impacts via customised dashboards and reports.
                    </p>
                    <Button variant="solid" size="large" label="LOGIN" onClick={doLogin}/>
                    <img src={AboutPhoto2} className="rounded-2xl" alt="Four people wearing uniforms are working together outside on the grass. They are gardening."/>
                </div>
                <div className="flex flex-col items-stretch gap-8 px-5 py-10 bg-grey03 bg-about-center-mobile bg-b-166-r bg-no-repeat">
                    <div className="flex flex-col items-stretch gap-6">
                        <div className="flex flex-col items-stretch gap-4">
                            <h3 className="font-vg-medium text-black text-[28px]">What is Seedkit?</h3>
                            <p className="font-vg-book text-black text-base leading-130">
                                Measuring and communicating impact is difficult but important for many purpose-led organisations. 
                                For social enterprises, it can be even more complex because it is important to report on both your impact and the sustainability 
                                of your business operations. In addition, the impact you are generating together with your business success needs to be reported to 
                                many different stakeholders to provide a full picture of your social enterprise.  
                                <br/><br/>
                                Seedkit solves these problems. It is a self-managed impact reporting and learning tool designed with and for social enterprises. 
                                It enables you to select or create indicators that accurately reflect your social enterprise's impact goals and business operations. 
                                It provides you with a simple and streamlined way of entering and monitoring data for each indicator and it generates 
                                meaningful data dashboards and downloadable reports for stakeholders.
                                <br/><br/>
                                The other benefit of Seedkit is that it can produce insights about the social enterprise sector. The developers of Seedkit are 
                                highly experienced social enterprise researchers who are able to aggregate and analyse de-identified data from Seedkit to generate insights 
                                about the sector and provide social enterprises, policy-makers and funders with the evidence they need to help the sector thrive.   
                            </p>
                        </div>
                        <img src={AboutPhoto3} alt="Smiling woman standing at a desk in a meeting room with indicator visualisations to the left of her"/>
                    </div>
                    <div className="flex flex-col items-stretch gap-6">
                        <h3 className="font-vg-medium text-black text-[28px]">Who is behind Seedkit?</h3>
                        <p className="font-vg-book text-base">
                            Seedkit is owned and hosted by Melbourne Social Equity Institute at the University of Melbourne. 
                            Seedkit was co-designed with a group of social enterprises, including those listed below, who generously shared their time and insights. 
                            Any errors on the website are the responsibility of the Seedkit team. 
                        </p>
                        <ul className="gap-4 list-disc list-inside">
                            <li className="font-vg-book text-base">Asylum Seeker Resource Centre</li>  
                            <li className="font-vg-book text-base">Aussie Veterans Coffee Co.</li> 
                            <li className="font-vg-book text-base">Bendigo Foodshare</li>  
                            <li className="font-vg-book text-base">Centre for Participation</li>   
                            <li className="font-vg-book text-base">Cirque du Soil</li>   
                            <li className="font-vg-book text-base">CURATE</li> 
                            <li className="font-vg-book text-base">ecoDynamics Nursery</li>   
                            <li className="font-vg-book text-base">Family Life</li>  
                            <li className="font-vg-book text-base">FLOAT</li>   
                            <li className="font-vg-book text-base">For Change Co.</li>  
                            <li className="font-vg-book text-base">Free to Feed</li>  
                            <li className="font-vg-book text-base">Fruit2Work</li> 
                            <li className="font-vg-book text-base">Gippsland Community Radio Society</li>   
                            <li className="font-vg-book text-base">Good Cycles</li>   
                            <li className="font-vg-book text-base">Greatest Good</li>   
                            <li className="font-vg-book text-base">Helm Youth Services</li> 
                            <li className="font-vg-book text-base">Hepburn Energy</li>  
                            <li className="font-vg-book text-base">Homeable</li>  
                            <li className="font-vg-book text-base">Humanitarian Advisory Group</li>    
                            <li className="font-vg-book text-base">Kew Neighbourhood Learning Centre</li>  
                            <li className="font-vg-book text-base">Lively</li>  
                            <li className="font-vg-book text-base">Moral Fairground</li>  
                            <li className="font-vg-book text-base">Mountain Monthly Co-operative</li>   
                            <li className="font-vg-book text-base">Sacred Heart Mission</li>  
                            <li className="font-vg-book text-base">Segue Community Hub & Arts Cafe</li>   
                            <li className="font-vg-book text-base">Servants Community Housing</li>   
                            <li className="font-vg-book text-base">Social Good Outpost</li>  
                            <li className="font-vg-book text-base">STREAT</li>  
                            <li className="font-vg-book text-base">Urban Camp Melbourne Cooperative</li>   
                            <li className="font-vg-book text-base">Wonthaggi Miners Dispensary</li>  
                            <li className="font-vg-book text-base">Yea Community House</li> 
                            <li className="font-vg-book text-base">100 Story Building</li>
                        </ul>
                        <p className="font-vg-book text-base">
                            Seedkit's genesis was in action research conducted through the Social Enterprise Impact Lab, 
                            co-funded by Lord Mayor's Charitable Foundation and Family Life. 
                            The initial development of Seedkit was conducted in partnership with the Centre for Social Impact Swinburne 
                            and funded by Victoria State Government.
                        </p>
                        <img src={AboutPhoto4} alt="A video conference call on a laptop screen with a grid of smiling faces visible. To the right hand side of this is a whiteboard covered with Post-It Notes."/>
                    </div>
                </div>
                <SubscribeForm/>
                <LogoBlock/>
                <Footer className="mx-5"/>
            </div>
        )}
        </>
    );
}