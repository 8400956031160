import { useEffect } from 'react';
import { useWindowSize } from 'usehooks-ts';

import UiUtils from '../../utils/UiUtils';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

export default function HelpPage() {
    const { width } = useWindowSize();
    const isMobile = UiUtils.isMobile(width);
    const isDesktop = !isMobile;

    /* Matomo Tracking Code */

    useEffect(() => {
        const title = 'Help';
        document.title = title;
        var _mtm = window._mtm = window._mtm || [];
        _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
        _mtm.push({'setDocumentTitle': title });
        var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
        g.async=true; g.src='https://matomo-staging.seedkit.com.au/js/container_VevA4SEN.js'; s.parentNode.insertBefore(g,s);
    }, []);
    
    return(
        <>
        {isDesktop && (
            <div className="box-border w-full h-full px-16 py-6 flex flex-col items-stretch gap-16">
                <Header title="Help"/>
                <Footer/>
            </div>
        )}
        {isMobile && (
            <div className="box-border w-full h-full px-5 py-6 flex flex-col items-stretch gap-12">
                <Header title="Help"/>
                <Footer/>
            </div>
        )}
        </>
    );
}